/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { graphql, useStaticQuery ,Link} from "gatsby"
import React, { ReactNode } from "react"
import { oc } from "ts-optchain"
import { SiteTitleQueryQuery } from "../graphqlTypes"
import Header from "./header"

import "./layout.css"

interface Props {
  children: ReactNode
}

const useSiteTitle = () => {
  const data: SiteTitleQueryQuery = useStaticQuery(
    graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return oc(data).site.siteMetadata.title("")
}

const Layout: React.FC<Props> = ({ children }, props) => {
  const title = useSiteTitle()

  return (
    <>
      <Header siteTitle={title}/>
      <div
        style={{
          margin: `20 auto`,
          maxWidth: "100%",
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
          zIndex: 12
        }}
      >
        <main>{children}</main>
        
        <footer
        style={{
          margin:"30px 30px",
          float:"right",
          textAlign:"right",
          fontFamily: "'futura-pt', Helvetica, sans-serif"
        }}>
          1200 Park St. Columbia, SC 29201<br/>
          info@parktogive.com<br/>
          (803) 939-6831 (M-F 9-5pm)<br/>
          <Link to="/home/">home</Link> | <Link to="/about/">about</Link> | <Link to="/faq/">faq</Link> | <Link to="/contact/">contact</Link> | <Link to="/terms/">terms</Link> | <Link to="/privacy-policy/">privacy</Link> 
          <br/>
          <Link to="/notices/">© {new Date().getFullYear()} Park to Give</Link>
        </footer>
      </div>
    </>
  )
}

export default Layout;


