import { Link, graphql } from "gatsby"
import React from "react"
import { checkPropTypes } from "prop-types";
import ColaSky from "../components/colasky"
import Image from "../components/image"
import Button from '@material-ui/core/Button'
import { createGenerateClassName } from "@material-ui/styles";

const styles = require('../styles/header.module.css')


interface Props {
  siteTitle?: string
}

const Header: React.FC<Props> = ({ siteTitle }) => (
  <header
    className={styles.headerDefault}
  >
    <div
      style={{

        background: '#ffffff'
      }}
    >
    </div>

    <div className={styles.headerDefault}>

      <div className={styles.headerBackground}>
        <div className={styles.backgroundCola}>
          <ColaSky />
        </div>
        <div className={styles.bannerHeader}>
          <div className={styles.bannerLeft}>
            ☎ (803) 939-6831 info@parktogive.com
      </div>
      <div className={styles.bannerNav}>
            <div className={styles.navdrop}>
              <div className={styles.dropdown}>
                <button className={styles.dropbtn}>≡</button>
                <div className={styles.dropdowncontent}>
                  <Link to="/home/">home</Link>
                  <Link to="/about/">about</Link>
                  <Link to="/faq/">faq</Link>
                  <Link to="/contact/">contact</Link>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.bannerCenter}>
            <div style={{width: "15%", margin:"0px auto"}}>
              <Image />
              
            </div>
            <h1 style={{ margin: "auto", textAlign: "center", fontSize: "20px" }} className={styles.logo}>
            <Link
              to="/home/"
              style={{
                color: `white`,
                textDecoration: `none`,
                fontFamily: 'futura-pt, Helvetica, sans-serif'
              }}
            >
              {siteTitle}
            </Link>
            
          </h1>
          </div>
          <div className={styles.bannerRight}>
            <div className={styles.bannerLinks}><Link to="/home/">home</Link></div>
              <div className={styles.bannerLinks}> <Link to="/about/">about</Link></div>
              <div className={styles.bannerFaq}><Link to="/faq/">faq</Link></div>
              <div className={styles.bannerLinks}><Link to="/contact/">contact</Link></div>
          </div>
          
        </div>
        <div style={{ position: "absolute", bottom: "40%", left: "25%", transform: "translate(-50%,-50%)", display: "flex", alignItems: "left" }} >
          
        </div>
      </div>
    </div>

  </header>

)

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;

