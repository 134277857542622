/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import { oc } from "ts-optchain"
import { SiteMetadataQueryQuery } from "../graphqlTypes"

type MetaProps = JSX.IntrinsicElements["meta"]

interface Props {
  description?: string
  lang?: string
  meta?: MetaProps[]
  title: string
}

const SEO: React.FC<Props> = ({ description, lang, meta, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetadataQuery {
        site {
          siteMetadata {
            title
            description
            author
            siteURL
            siteImage
            keywords
          }
        }
      }
    `
  )

  const metaDescription = description || oc(site).siteMetadata.description("Park To Give - find local parking while helping charity at the same time")
  const metaSiteURL = oc(site).siteMetadata.siteURL("")
  const metaSiteImage = oc(site).siteMetadata.siteImage("")
  const metaKeywords = oc(site).siteMetadata.keywords("")
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${oc(site).siteMetadata.title("")}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `image`,
          content: metaSiteImage,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaSiteImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: oc(site).siteMetadata.author(""),
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaSiteImage,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          name: `siteURL`,
          content: metaSiteURL,
        },

        // @ts-ignore
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default SEO
