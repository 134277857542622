import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react"
import { ImageQueryQuery } from "../../graphql-types"

const ColaSky: React.FC = () => {
    const data: ImageQueryQuery = useStaticQuery(graphql`query colaQuery {
  placeholderImage: file(relativePath: {eq: "colasky.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)
  return  (
    <GatsbyImage image={data.placeholderImage?.childImageSharp?.gatsbyImageData} alt="Columbia Skyline"/>
  ) ;
}

export default ColaSky