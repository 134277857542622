import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react"
import { ImageQueryQuery } from "../../graphql-types"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image: React.FC = () => {
  const data: ImageQueryQuery = useStaticQuery(graphql`query ImageQuery {
  placeholderImage: file(relativePath: {eq: "p2glogo.png"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)

  return  (
    <GatsbyImage image={data.placeholderImage?.childImageSharp?.gatsbyImageData} alt="Park To Give Logo" />
  );
}
export default Image
